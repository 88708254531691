<template>
    <dashBoardDetails/>
</template>

<script>
import dashBoardDetails from '../../components/dashboard/dashBoardDetails.vue';
export default {
    name: "Home",
    components: {
    dashBoardDetails,
  },
}
</script>