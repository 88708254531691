<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card  rounded="lg" elevation="3">
          <h1 class="pa-3">Welcome to Dashboard</h1>
          <p class="pl-3 pr-3 pb-3">
            Hello Jone Doe, welcome to your awesome dashboard!
          </p>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-center">
        <div class="box primary pa-3">
          <i class="mdi mdi-eye-outline"></i>
          <h3>5,154</h3>
          <p class="lead">Page views</p>
        </div>
      </v-col>
      <v-col cols="3" class="text-center">
        <div class="box error pa-3">
          <i class="mdi mdi-account-outline"></i>
          <h3>245</h3>
          <p class="lead">User registered</p>
        </div>
      </v-col>
      <v-col cols="3" class="text-center">
        <div class="box yellow darken-2 pa-3">
          <i class="mdi mdi-cart-outline"></i>
          <h3>5,154</h3>
          <p class="lead">Product sales</p>
        </div>
      </v-col>
      <v-col cols="3" class="text-center">
        <div class="box success pa-3">
          <i class="mdi mdi-credit-card-outline"></i>
          <h3>5,154</h3>
          <p class="lead">Transactions</p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card elevation="3">
          <barChart/>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card elevation="3">
          <doughnutChart/>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card elevation="3">
          <lineChart/>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card elevation="3">
          <radarChart/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import barChart from "./barChart.vue"
import doughnutChart from "./doughnutChart.vue"
import lineChart from "./lineChart.vue"
import radarChart from "./radarChart.vue"

export default {
  name: "dashBoardDetail",
  components:{
    barChart,
    doughnutChart,
    lineChart,
    radarChart,
  },
};
</script>

<style scoped>
.box {
  color: #f3f4f6;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
.box h3:after {
  content: "";
  height: 2px;
  width: 70%;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.12);
  display: block;
  margin-top: 10px;
}
.box i {
  position: absolute;
  height: 70px;
  width: 70px;
  font-size: 22px;
  padding: 15px;
  top: -25px;
  left: -25px;
  background-color: rgba(255, 255, 255, 0.15);
  line-height: 60px;
  text-align: right;
  border-radius: 50%;
}
</style>
